<template>
    <div>

        <NavBar></NavBar>

        <div class="card-centre">
            <div class="heading">Online Payments</div>

            <div class="bullet">
                <p>
                    We use
                    <a href="https://stripe.com/en-gb" target="_blank">Stripe</a>
                    and
                    <a href="https://www.paypal.com/" target="_blank">PayPal</a>
                    to process card and online wallet payments.
                    <br>
                    These industry-leading services provide secure transactions.
                    <br>
                    Our websites can't see the customer's payment details.
                </p>

            </div>
        </div>



        <div class="section">

            <div class="blurb bullet">
                <p>In this screenshot we've integrated Stripe's card payment controls into our own website.
                </p>

                <p>
                    The process is secure against tampering.
                    For instance, a hacker can't adjust the order total before payment because
                    the order is re-evaluated by our backend cloud computing.
                </p>

            </div>

            <div class="card">
                <img id="pick-a-slot-img" class="card-image" src="../assets/payment.jpg">

                <div class="heading">Payment Integrations</div>
                <div class="bullet">Simple and secure</div>
            </div>

        </div>


        <div class="section">

            <div class="card">
                <img id="stripe-dash-img" class="card-image" src="../assets/stripe-dash.jpg">

                <div class="heading">Stripe Dashboard</div>
                <div class="bullet">View payments</div>
                <div class="bullet">Handle refunds</div>
            </div>

            <div class="blurb bullet">
                <p>
                    Stripe and PayPal provide you with a web interface including a dashboard and list of payments
                    where you can see all your info and deal with refunds.
                </p>

            </div>

        </div>


    </div>
</template>

<script>
    import NavBar from "../components/NavBar";

    export default {
        name: "Payments",
        components: {NavBar}
    }
</script>

<style scoped lang="scss">
    @import "views-common";

</style>