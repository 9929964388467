

let maxAnims = 20;

export function getAnimElementById(name) {
    let el = document.getElementById(name);
    if (!el) {
        console.log(`element not found '${name}`);
        return;
    }
    if (el.getAnimations().length >= maxAnims) {
        console.error('anims are accumulating in ' + name);
        return;
    }
    return el;
}

export function getAnimElementsByClassName(name) {
    let els = document.getElementsByClassName(name);
    let result = [];
    for (let el of els) {
        if (el.getAnimations().length >= maxAnims) {
            console.error('anims are accumulating ' + name);
            continue;
        }
        result.push(el);
    }
    return result;
}
