<template>
    <div class="sign-up-icons">

<!--        <Head id="head1" class="animated-icon" fill="#819ea0" stroke="#71768e" fill-opacity="1"></Head>-->
        <img class="animated-icon laptop" src="../../assets/laptop.svg">
        <img class="animated-icon paper" src="../../assets/paper.svg">

        <Head
            v-for="i in numHeads" :key="'head'+i"
            :id="'head'+i" class="animated-icon head"
            :fill="headAnimData[i-1].fill" :stroke="headAnimData[i-1].stroke" stroke-width="20" fill-opacity="1">
        </Head>

        <Head
            v-for="i in numMinis" :key="'mini'+i"
            :id="'mini'+i" class="animated-icon mini"
            :fill="miniAnimData[i-1].fill" :stroke="miniAnimData[i-1].stroke" stroke-width="40" fill-opacity="0">
        </Head>

        <img
            v-for="i in numMinis" :key="'user-data'+i"
            :id="'user-data'+i" class="animated-icon user-data"
            src="../../assets/user-data.svg"
        >



    </div>
</template>

<script>

    import {getAnimElementById} from "./anim_common";
    import Head from "../anims/Head";

    let intervals = [];

    let numHeads = 4;
    let headStartX = 55;
    let headStartY = 79;
    let headOffX = -14;
    let headOffY = -2;
    let headAnimData = [];

    let numMinis = 4;
    let miniStartX = 73;
    let miniStartY = 44;
    let miniOffX = 0;
    let miniOffY = -10;
    let miniAnimData = [];
    let miniDataOffX = 11;
    let miniDataOffY = -1;


    function newColPair() {
        let fillR = Math.round(1 + Math.random() * 254);
        let fillG = Math.round(1 + Math.random() * 254);
        let fillB = Math.round(1 + Math.random() * 254);
        let fillBright = Math.round(0.299*fillR + 0.587*fillG + 0.114*fillB);

        let strokeBright = fillBright >= 128 ? fillBright - 32 : fillBright + 32;
        let strokeR = fillR * strokeBright / fillBright;
        let strokeG = fillG * strokeBright / fillBright;
        let strokeB = fillB * strokeBright / fillBright;

        return [
            `rgb(${fillR}, ${fillG}, ${fillB})`,
            `rgb(${strokeR}, ${strokeG}, ${strokeB})`
        ];
    }

    for (let i=0; i<numHeads; i++) {
        let [fill, stroke] = newColPair();
        headAnimData.push({
            fill: fill,
            stroke: stroke,
            x: headStartX + i * headOffX,
            y: headStartY + i * headOffY,
        });
    }

    for (let i=0; i<numMinis; i++) {
        let [fill, stroke] = newColPair();
        miniAnimData.push({
            fill: fill,
            stroke: stroke,
            x: miniStartX + i * miniOffX,
            y: miniStartY + i * miniOffY,
        });
    }





    export default {
        name: "SignUpAnim",
        components: {Head},
        data() {
            return {
                numHeads,
                numMinis,
                headAnimData: headAnimData,
                miniAnimData: miniAnimData,
            };
        },

        mounted() {
            this.animateHeads();
            intervals.push(setInterval(this.animateHeads, 4000));

            this.animateMinis();
            setTimeout(() => intervals.push(setInterval(this.animateMinis, 4000), 2000));
        },

        beforeDestroy() {
            for (let i of intervals) {
                clearInterval(i);
            }
            intervals = [];
        },

        methods: {

            animateHeads() {
                if (!document.hasFocus()) {
                    return;
                }

                for (let i=0; i<3; i++) {
                    this.headAnimData[i].fill = this.headAnimData[i+1].fill;
                    this.headAnimData[i].stroke = this.headAnimData[i+1].stroke;
                }
                let [fill, stroke] = newColPair();
                this.headAnimData[3].fill = fill;
                this.headAnimData[3].stroke = stroke;


                for (let i=0; i<numHeads; i++) {

                    let head = getAnimElementById('head' + (i+1));
                    if (!head) {
                        console.log("no head");
                        return
                    }

                    let x = headAnimData[i].x;
                    let y = headAnimData[i].y;
                    head.animate(
                        [
                            {left: `${x+headOffX}%`, easing: 'ease-in-out'},
                            {left: `${x}%`},
                        ],
                        {duration: 1500, fill: "forwards"}
                    );
                    head.animate(
                        [
                            {top: `${y+headOffY}%`, easing: 'ease-in-out'},
                            {top: `${y}%`},
                        ],
                        {duration: 1500, fill: "forwards"}
                    );

                }

                let head1 = getAnimElementById('head1');
                head1.animate(
                    [
                        {opacity: 1, easing: 'ease-in'},
                        {opacity: 0},
                    ],
                    {duration: 1000, fill: "forwards"}
                );

                let head4 = getAnimElementById('head4');
                head4.animate(
                    [
                        {opacity: 0, easing: 'ease-in'},
                        {opacity: 1},
                    ],
                    {duration: 1000, fill: "forwards"}
                );

            },

            animateMinis() {
                if (!document.hasFocus()) {
                    return;
                }

                for (let i=3; i>=1; i--) {
                    this.miniAnimData[i].fill = this.miniAnimData[i-1].fill;
                    this.miniAnimData[i].stroke = this.miniAnimData[i-1].stroke;
                }
                this.miniAnimData[0].fill = this.headAnimData[1].fill;
                this.miniAnimData[0].stroke = this.headAnimData[1].stroke;


                for (let i=0; i<numHeads; i++) {

                    let mini = getAnimElementById('mini' + (i+1));
                    let userData = getAnimElementById('user-data' + (i+1));
                    if (!mini || !userData) {
                        console.log("no mini or userData element");
                        return
                    }

                    let fromX = miniAnimData[i].x;
                    let fromY = miniAnimData[i].y;
                    let toX = fromX + miniOffX;
                    let toY = fromY + miniOffY;

                    if (i===0) {
                        fromX = 50;
                        fromY = 40;
                    }

                    // as we scale and shear for 3d roll effect
                    if (i===3) {
                        toX -= 2;
                    }

                    mini.animate(
                        [
                            {left: `${fromX}%`, easing: 'ease-in-out'},
                            {left: `${toX}%`},
                        ],
                        {duration: 1000, fill: "forwards"}
                    );
                    mini.animate(
                        [
                            {top: `${fromY}%`, easing: 'ease-in-out'},
                            {top: `${toY}%`},
                        ],
                        {duration: 1000, fill: "forwards"}
                    );

                    userData.animate(
                        [
                            {left: `${fromX + miniDataOffX}%`, easing: 'ease-in-out'},
                            {left: `${toX + miniDataOffX}%`},
                        ],
                        {duration: 1000, fill: "forwards"}
                    );
                    userData.animate(
                        [
                            {top: `${fromY + miniDataOffY}%`, easing: 'ease-in-out'},
                            {top: `${toY + miniDataOffY}%`},
                        ],
                        {duration: 1000, fill: "forwards"}
                    );

                }

                let mini1 = getAnimElementById('mini1');
                mini1.animate(
                    [
                        {opacity: 0, easing: 'ease-in'},
                        {opacity: 1},
                    ],
                    {duration: 500, fill: "forwards"}
                );

                let userData1 = getAnimElementById('user-data1');
                userData1.animate(
                    [
                        {opacity: 0, easing: 'ease-in'},
                        {opacity: 1},
                    ],
                    {duration: 500, fill: "forwards"}
                );


                let mini4 = getAnimElementById('mini4');
                mini4.animate(
                    [
                        {opacity: 1, easing: 'ease-in'},
                        {opacity: 0},
                    ],
                    {duration: 750, fill: "forwards"}
                );
                mini4.animate(
                    [
                        {transform: "translate(-50%, -50%) scaleY(1.0) skewX(0)", easing: 'ease-in'},
                        {transform: "translate(-50%, -50%) scaleY(0.1) skewX(30deg)"},
                    ],
                    {duration: 600, delay: 150}
                );

                let userData4 = getAnimElementById('user-data4');
                userData4.animate(
                    [
                        {opacity: 1, easing: 'ease-in'},
                        {opacity: 0},
                    ],
                    {duration: 750, fill: "forwards"}
                );
                userData4.animate(
                    [
                        {transform: "translate(-50%, -50%) scaleY(1.0) skewX(0)", easing: 'ease-in'},
                        {transform: "translate(-50%, -50%) scaleY(0.1) skewX(30deg)"},
                    ],
                    {duration: 600, delay: 150}
                );

            },

        }
    }

</script>

<style scoped lang="scss">

    @import "anim_common.css";

    .sign-up-icons {
        background-image: linear-gradient(to right bottom, #ff8080, #fe86a0, #f590bc, #e69dd3, #d5aae3);
    }

    .head {
        /*width: 150px;*/
        width: 12vw;
    }

    .mini {
        /*width: 25px;*/
        width: 2vw;
    }

    .user-data {
        /*width: 60px;*/
        width: 5vw;
    }

    .laptop {
        left: 48%;
        top: 58%;
        /*width: 40%;*/
        width: 11vw;
    }

    .paper {
        left: 80%;
        top: 28%;
        /*width: 30%;*/
        width: 10.5vw;
    }

    @media (max-width: 600px) {
        .head {
            width: 24vw;
        }

        .mini {
            width: 4vw;
        }

        .user-data {
            width: 10vw;
        }

        .laptop {
            width: 22vw;
        }

        .paper {
            width: 21vw;
        }
    }



</style>