<template>

    <div class="payment-icons">
        <img id="mobile" class="animated-icon" src="../../assets/mobile.svg">

        <img id="pig" class="animated-icon" src="../../assets/pig.png">

        <div class="animated-icon coin-outer">
            <img class="coin" src="../../assets/coin.svg">
        </div>
        <div class="animated-icon coin-outer">
            <img class="coin" src="../../assets/coin.svg">
        </div>
        <div class="animated-icon coin-outer">
            <img class="coin" src="../../assets/coin.svg">
        </div>

        <img id="pig-overlay" class="animated-icon" src="../../assets/pig-overlay.png">

        <img id="finger" class="animated-icon" src="../../assets/finger.svg">
        <img id="finger-press" class="animated-icon" src="../../assets/finger-press.svg">
    </div>

</template>

<script>
    import {getAnimElementById, getAnimElementsByClassName} from "./anim_common";

    let animInterval = null;

    let anim3s = {
        "0.000s": 0.000,
        "0.125s": 0.042,
        "0.250s": 0.083,
        "0.375s": 0.125,
        "0.500s": 0.167,
        "0.625s": 0.208,
        "0.750s": 0.25,
        "0.875s": 0.292,
        "1.000s": 0.333,
        "1.125s": 0.375,
        "1.250s": 0.417,
        "1.375s": 0.458,
        "1.500s": 0.5,
        "1.625s": 0.542,
        "1.750s": 0.583,
        "1.875s": 0.325,
        "2.000s": 0.667,
        "2.125s": 0.708,
        "2.250s": 0.75,
        "2.375s": 0.792,
        "2.500s": 0.833,
        "2.625s": 0.875,
        "2.750s": 0.917,
        "2.875s": 0.958,
        "3.000s": 1.000,
    };

    export default {
        name: "PaymentAnim",

        mounted() {
            this.animateIcons();
            animInterval = setInterval(this.animateIcons, 4000);
        },

        beforeDestroy() {
            if (animInterval) {
                clearInterval(animInterval);
            }
        },

        methods: {

            animateIcons() {
                if (!document.hasFocus()) {
                    return;
                }

                this.animateFinger();
                this.animateCoins();
            },

            animateFinger() {
                let finger = getAnimElementById('finger');
                let fingerPress = getAnimElementById('finger-press');
                if (!finger || !fingerPress) {
                    return
                }

                finger.animate(
                    [
                        {offset: anim3s["0.000s"], left: '10%', transform: 'translate(-50%, -50%) rotate(0)', easing: 'ease'},
                        {offset: anim3s["1.000s"], left: '20%', transform: 'translate(-50%, -50%) rotate(10deg)'},
                        {offset: anim3s["3.000s"], left: '20%', transform: 'translate(-50%, -50%) rotate(10deg)'},
                    ],
                    {duration: 3000}
                );
                finger.animate(
                    [
                        {offset: anim3s["0.000s"], top: '65%', easing: 'ease-in-out'},
                        {offset: anim3s["1.000s"], top: '55%'},
                        {offset: anim3s["3.000s"], top: '55%'},
                    ],
                    {duration: 3000}
                );

                finger.animate(
                    [
                        {offset: anim3s["0.000s"], opacity: 0},
                        {offset: anim3s["0.750s"], opacity: 1},
                        {offset: anim3s["1.000s"], opacity: 1, easing: 'step-start'},
                        {offset: anim3s["1.125s"], opacity: 0, easing: 'step-start'},
                        {offset: anim3s["1.250s"], opacity: 1},
                        {offset: anim3s["2.250s"], opacity: 1},
                        {offset: anim3s["2.750s"], opacity: 0},
                        {offset: anim3s["3.000s"], opacity: 0},
                    ],
                    {duration: 3000}
                );
                fingerPress.animate(
                    [
                        {offset: anim3s["0.000s"], opacity: 0, left: '20%', top: '55%', transform: 'translate(-50%, -50%) rotate(10deg)'},
                        // {offset: anim3s["1.000s"], opacity: 0, easing: 'step-start'},
                        // todo: why?
                        {offset: 0.322, opacity: 0, easing: 'step-start'},
                        {offset: anim3s["1.125s"], opacity: 1, easing: 'step-start'},
                        {offset: anim3s["1.250s"], opacity: 0},
                        {offset: anim3s["3.000s"], opacity: 0, left: '20%', top: '55%', transform: 'translate(-50%, -50%) rotate(10deg)'},
                    ],
                    {duration: 3000}
                );

            },

            animateCoins() {
                let coinOuters = getAnimElementsByClassName("coin-outer");
                let pig = getAnimElementById('pig');
                let pigOverlay = getAnimElementById('pig-overlay');

                for (let coinOuter of coinOuters) {
                    let startX = 72 - 20 + Math.random() * 40;
                    let delay = 0.4 + Math.random() * 0.8;

                    coinOuter.animate(
                        [
                            {offset: 0, left: `${startX}%`},
                            {offset: (1.25+delay)/4, left: `${startX}%`, easing: 'ease-out'},
                            // {offset: (1.25+delay)/4, left: `${startX}%`, easing: 'cubic-bezier(0, 0.25, 0.25, 1)'},
                            {offset: (2.25+delay)/4, left: '72%'},
                            {offset: 1, left: '72%'},
                        ],
                        {duration: 4000}
                    );

                    coinOuter.animate(
                        [
                            {offset: 0, top: '-30%'},
                            {offset: (1.25+delay)/4, top: '-30%', easing: 'ease-in'},
                            {offset: (2.25+delay)/4, top: '50%'},
                            {offset: 1, top: '50%'},
                        ],
                        {duration: 4000}
                    );

                    coinOuter.animate(
                        [
                            {offset: 0, opacity: 0},
                            {offset: (1.125+delay)/4, opacity: 0},
                            {offset: (1.25+delay)/4, opacity: 1},
                            {offset: (2.25+delay)/4, opacity: 1},
                            {offset: 1, opacity: 0},
                        ],
                        {duration: 4000}
                    );



                    let coin = coinOuter.childNodes[0];
                    if (coin.getAnimations().length > 20) {
                        break;
                    }

                    let rot = -180*2 + Math.random() * 360*2;
                    coin.animate(
                        [
                            // {transform: `rotate(0) scale(1.0)`},
                            // {transform: `rotate(${rot}deg) scale(0.5)`},

                            {offset: 0, transform: `rotate(0) scale(1.0)`},
                            {offset: (1.125+delay)/4, transform: `rotate(0) scale(1.0)`, easing: 'ease-in'},
                            {offset: (2.25+delay)/4, transform: `rotate(${rot}deg) scale(0.5)`},
                            {offset: 1, transform: `rotate(${rot}deg) scale(0.5)`},

                        ],
                        {duration: 4000}
                    );




                    if (pig && pigOverlay) {
                        rot = -3 + Math.random() * 6;
                        let pigFrames = [
                            // {offset: 0, transform: 'translate(50%, 50%) scale(1.0) rotate(0)'},
                            // {offset: (2.15 + delay) / 4, transform: 'translate(50%, 50%) scale(1.0) rotate(0)'},
                            // {offset: (2.25 + delay) / 4, transform: `translate(50%, 52%) scale(1.05) rotate(${rot}deg)`},
                            // {offset: (2.35 + delay) / 4, transform: 'translate(50%, 50%) scale(1.0) rotate(0)'},
                            // {offset: 1, transform: 'translate(50%, 50%) scale(1.0) rotate(0)'},
                            {offset: 0, transform: 'scale(1.0) rotate(0)'},
                            {offset: (2.15 + delay) / 4, transform: 'scale(1.0) rotate(0)'},
                            {offset: (2.25 + delay) / 4, transform: `scale(1.05) rotate(${rot}deg)`},
                            {offset: (2.35 + delay) / 4, transform: 'scale(1.0) rotate(0)'},
                            {offset: 1, transform: 'scale(1.0) rotate(0)'},
                        ];

                        pig.animate(pigFrames, {duration: 4000, composite: 'add'})
                        pigOverlay.animate(pigFrames, {duration: 4000, composite: 'add'})
                    }

                }


                // let coins = document.getElementsByClassName("coin");
                // for (let coin of coins) {
                //     if (coin.getAnimations().length > 20) {
                //         // console.error('anims are accumulating, not adding more');
                //         break;
                //     }
                //
                //     let rot = -180*4 + Math.random() * 360*4;
                //     coin.animate(
                //         [
                //             {transform: `rotate(0) scale(1.0)`},
                //             {transform: `rotate(${rot}deg) scale(0.5)`},
                //         ],
                //         {duration: 4000, fill: "forwards"}
                //     );
                // }

            },


        }
    }

</script>

<style scoped lang="scss">

    @import "anim_common.css";

    .payment-icons {
        background-image: linear-gradient(to left bottom, #ffd693, #ffc185, #ffac7e, #ff967c, #ff8080);
    }

    #mobile {
        left: 20%;
        top: 35%;
        /*height: 96px;*/
        height: 9vw;
    }

    #finger, #finger-press {
        left: 20%;
        top: 45%;
        opacity: 0;
        /*height: 120px;*/
        height: 12vw;
    }

    #pig, #pig-overlay {
        left: 70%;
        top: 55%;
        /*height: 128px;*/
        height: 12vw;
    }

    .coin-outer {
        left: 70%;
        top: -30%;
    }

    .coin {
        /*height: 64px;*/
        height: 6vw;
    }

    @media (max-width: 600px) {
        #mobile {
            height: 18vw;
        }

        #finger, #finger-press {
            height: 24vw;
        }

        #pig, #pig-overlay {
            height: 24vw;
        }

        .coin {
            /*height: 64px;*/
            height: 12vw;
        }
    }


</style>