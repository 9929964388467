<template>
    <div>
    <div id="minimal" class="minimal">

        <canvas id="bg" width="1000" height="1000"></canvas>

        <canvas id="fg"  width="1000" height="1000"
                @mousemove="onMouseMove" @touchmove="onTouchMove">
        </canvas>

<!--        <div v-if="showResizes" class="resizes">-->
<!--            <p v-for="resize of resizes" :key="resize">{{resize}}</p>-->
<!--        </div>-->


    </div>
    </div>
</template>

<script>
    import skySrc from '../assets/cloud-wide.jpg';

    // let resizes = ["resizes"];
    let resizeTimeoutId = null;
    let resizeTimeoutLength = 100;
    let hasResized = false;


    let animFrameRequest = null;

    let skyImage = new Image();
    skyImage.src = skySrc;

    let frameNum = 0;

    class SineVar {

        // todo: this var is controlling more than just sine vars
        static frameNum = 2000;
        static globalSpeed = 0.001;

        constructor(min, max, speed, frameOffset) {
            this.min = min;
            this.max = max;
            this.size = this.max - this.min;
            this.speed = speed;
            this.frameOffset = frameOffset;
        }

        val() {
            return this.min + (this.size * 0.5) + Math.sin((SineVar.frameNum + this.frameOffset) * SineVar.globalSpeed * this.speed) * (this.size * 0.5);
        }
    }


    let texts = [
        {text: "red kite dev",
            x: 0.5, y: 0.5, font: 'Diamonds', size: 75, measure: undefined},

        {text: "Y O U R   B U S I N E S S   O N L I N E",
            x: 0.5, y: 0.55, font: 'Raleway', size: 20, measure: undefined},
    ]


    let bgDiv = 2;





    // let bgCol = `#cc8800`;
    // let bgCol = `#cc6300`;
    // let bgCol = `#c24400`;
    // let bgCol = `#802000`;
    let bgCol = `#200000`;

    let fadeFreq = 4;
    // let fadeCol = '#010101';
    let fadeCol = '#010202';
    // let fadeCol = '#000101';


    let shrink = new SineVar(0.5, 0.5, 4.1, 0);
    let rot1 = new SineVar(-0.01, 0.01, 3.1,0);
    let centreXOff = new SineVar(-0.4, 0.4, 5.1,500);
    let centreYOff = new SineVar(-0.4, 0.4, 6,0);
    let arcLen = 1.95;
    let arcRotSpeed = 0;
    let arcRotOff = new SineVar(Math.PI * -1.0, Math.PI * 1.0, 2.5,0);
    let boxFrac = 0.618;
    let arcR = new SineVar(255, 225, 16,0);
    let arcG = new SineVar(225, 150, 16,0);
    let arcXOff = new SineVar(0, 0, 6.1,0);




    let mousex = 0;
    let mousey = 0;
    let mouseActiveExtend = 30;
    let mouseActive = 0;
    let mouseSize = 50.0;
    // let mouseCol = "#ffffff";
    let mouseCol = "#ffffff08";
    // let mouseCol = "#20000008";




    function measureTexts(fgCanvas, fgCtx, texts) {
        let leftMost = null;
        let rightMost = null;
        let topMost = null;
        let botMost = null;

        for (let text of texts) {
            fgCtx.font = `300 ${text.size}px "${text.font}"`;
            text.measure = fgCtx.measureText(text.text);

            let x = text.x * fgCanvas.width - text.measure.width * 0.5;
            let y = text.y * fgCanvas.height;
            text.left  = x - text.measure.actualBoundingBoxLeft;
            text.right = x + text.measure.actualBoundingBoxRight;
            text.top   = y - text.measure.actualBoundingBoxAscent;
            text.bot   = y + text.measure.actualBoundingBoxDescent;

            leftMost = Math.min(leftMost || text.left, text.left);
            rightMost = Math.max(rightMost || text.right, text.right);
            topMost = Math.min(topMost || text.top, text.top);
            botMost = Math.max(botMost || text.bot, text.bot);
        }

        return [leftMost, rightMost, topMost, botMost];
    }



    export default {
        name: "Banner",

        data() {
            return {
                // resizes,
                // showResizes: true,
            }
        },

        mounted() {
            this.resizeCanvases();
            window.addEventListener('resize', this.resizeCanvases);
            animFrameRequest = window.requestAnimationFrame(this.do_draw);
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
            cancelAnimationFrame(animFrameRequest);
            animFrameRequest = null;
        },

        methods: {

            resizeCanvases() {

                // while (resizes.length > 10) {
                //     resizes.shift();
                // }

                if (resizeTimeoutId) {
                    // resizes.push("cancel");
                    console.log('cancel');
                    clearTimeout(resizeTimeoutId);
                }

                // const fgCanvas = document.querySelector('#fg');
                // resizes.push(`${fgCanvas.clientWidth} ${fgCanvas.clientHeight} from ${fgCanvas.width} ${fgCanvas.height}`);

                if (!hasResized) {
                    console.log('immediate resize');
                    this._resizeCanvases();
                } else {
                    resizeTimeoutId = setTimeout(() => {
                        resizeTimeoutId = null;
                        this._resizeCanvases();
                    }, resizeTimeoutLength);
                }

            },

            _resizeCanvases() {
                const fgCanvas = document.querySelector('#fg');

                if (fgCanvas.width === fgCanvas.clientWidth && fgCanvas.height === fgCanvas.clientHeight) {
                    // resizes.push('ignore');
                    return;
                }
                // resizes.push("apply");
                console.log('apply');


                fgCanvas.width = fgCanvas.clientWidth;
                fgCanvas.height = fgCanvas.clientHeight;

                const bgCanvas = document.querySelector('#bg');
                bgCanvas.width = fgCanvas.width / bgDiv;
                bgCanvas.height = fgCanvas.height / bgDiv;

                let bgCtx = bgCanvas.getContext("2d", {alpha: false});

                bgCtx.fillStyle = bgCol;
                bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);

                if (fgCanvas.width > 400) {
                    texts[0].size = 75;
                    texts[1].size = 20;
                } else {
                    texts[0].size = 50;
                    texts[1].size = 14;
                }

                hasResized = true;
                console.log('hasResized');
            },

            onTouchMove(e) {
                let clientRect = e.target.getBoundingClientRect();
                mousex = e.changedTouches[0].clientX - clientRect.x;
                mousey = e.changedTouches[0].clientY - clientRect.y;
                mouseActive = Math.min(mouseActiveExtend, mouseActive + 3);
            },
            onMouseMove(e) {
                let clientRect = e.target.getBoundingClientRect();
                mousex = e.clientX - clientRect.x;
                mousey = e.clientY - clientRect.y;
                mouseActive = Math.min(mouseActiveExtend, mouseActive + 3);
            },

            do_draw() {
                frameNum++;
                if (frameNum === -1) {
                    console.log(frameNum);
                }
                SineVar.frameNum++;

                if (hasResized === false) {
                    console.log('not drawing, not yet hasResized');
                    window.requestAnimationFrame(this.do_draw);
                    return;
                }
                // console.log('drawing');

                // const fgCanvas = document.querySelector('#fg');
                const fgCanvas = document.getElementById('fg');
                if (!fgCanvas) {
                    return;
                }
                let fgCtx = fgCanvas.getContext("2d", {alpha: true});

                let stencilCol = fgCtx.createLinearGradient(0,0, 0,fgCanvas.clientHeight);
                stencilCol.addColorStop(0, '#707070');
                stencilCol.addColorStop(1, '#404040');

                fgCtx.clearRect(0, 0, fgCanvas.width, fgCanvas.height);

                fgCtx.fillStyle = stencilCol;
                fgCtx.fillRect(0, 0, fgCanvas.width, fgCanvas.height);


                // text border line rect
                fgCtx.globalCompositeOperation = 'destination-out';
                fgCtx.strokeStyle = '#ffffffff';
                let allTextBox = measureTexts(fgCanvas, fgCtx, texts);
                let boxWidth = (allTextBox[1] - allTextBox[0]) * boxFrac;
                fgCtx.lineWidth = 2;

                let boxLeft = fgCanvas.width*0.5 - boxWidth*0.5;
                let boxTop = fgCanvas.height*0.49 - boxWidth*0.5;
                let textHeight = allTextBox[3] - allTextBox[2];
                let border = 7;

                fgCtx.beginPath();
                fgCtx.moveTo(boxLeft, boxTop + boxWidth/2 - textHeight/2 - border);
                fgCtx.lineTo(boxLeft, boxTop);
                fgCtx.lineTo(boxLeft + boxWidth, boxTop);
                fgCtx.lineTo(boxLeft + boxWidth, boxTop + boxWidth/2 - textHeight/2 - border);
                fgCtx.stroke();

                fgCtx.beginPath();
                fgCtx.moveTo(boxLeft, boxTop + boxWidth/2 + textHeight/2 + border);
                fgCtx.lineTo(boxLeft, boxTop + boxWidth);
                fgCtx.lineTo(boxLeft + boxWidth, boxTop + boxWidth);
                fgCtx.lineTo(boxLeft + boxWidth, boxTop + boxWidth/2 + textHeight/2 + border);
                fgCtx.stroke();

                fgCtx.globalCompositeOperation = 'source-over';


                // text
                fgCtx.globalCompositeOperation = 'destination-out';
                fgCtx.fillStyle = '#ffffffff';
                for (let text of texts) {
                    let textX = fgCanvas.width * text.x - (text.measure.width * 0.5);
                    let textY = fgCanvas.height * text.y;
                    fgCtx.font = `300 ${text.size}px "${text.font}"`;
                    fgCtx.fillText(text.text, textX, textY);
                }
                fgCtx.globalCompositeOperation = 'source-over';


                const bgCanvas = document.querySelector('#bg');
                let bgCtx = bgCanvas.getContext("2d", {alpha: false});


                bgCtx.strokeStyle = `rgb(${arcR.val()}, ${arcG.val()}, 0)`;
                bgCtx.lineWidth = 2;

                let arcStart = (SineVar.frameNum * SineVar.globalSpeed * arcRotSpeed + arcRotOff.val()) % (2 * Math.PI);
                bgCtx.beginPath();
                bgCtx.arc(fgCanvas.width*(0.5+arcXOff.val())/bgDiv, fgCanvas.height*0.5/bgDiv, boxWidth/bgDiv, arcStart, arcStart + Math.PI * arcLen);
                bgCtx.stroke();



                if (mouseActive) {
                    let radius = mouseSize * (mouseActive / mouseActiveExtend);
                    // bgCtx.fillStyle = "#00ccff";
                    bgCtx.fillStyle = mouseCol;
                    bgCtx.beginPath();
                    bgCtx.arc(mousex/bgDiv, mousey/bgDiv, radius/bgDiv, 0, 2 * Math.PI);
                    bgCtx.fill();
                    mouseActive--;
                }



                let centreX = bgCanvas.width * 0.5;
                let centreY = bgCanvas.height * 0.5;

                let rotVal = rot1.val();
                let shrinkVal = shrink.val();

                bgCtx.translate(centreX, centreY);
                bgCtx.rotate(rotVal);
                // bgCtx.drawImage(bgCanvas, shrink.val()-centreX, shrink.val()-centreY, bgCanvas.width - (shrink.val()*2), bgCanvas.height - (shrink.val()*2))
                bgCtx.drawImage(bgCanvas, shrinkVal-centreX + centreXOff.val(), shrinkVal-centreY + centreYOff.val(), bgCanvas.width - (shrinkVal*2), bgCanvas.height - (shrinkVal*2))
                bgCtx.rotate(-rotVal);
                bgCtx.translate(-centreX, -centreY);


                if (SineVar.frameNum % fadeFreq === 0) {
                    // fade to black
                    bgCtx.globalCompositeOperation = 'difference';
                    bgCtx.fillStyle = fadeCol;
                    bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height)
                }

                // black to bg
                bgCtx.globalCompositeOperation = 'lighten';
                bgCtx.fillStyle = bgCol;
                bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height)


                bgCtx.globalCompositeOperation = 'source-over';
                window.requestAnimationFrame(this.do_draw);

            }

        }
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

    @font-face {
        font-family: "Diamonds";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('../assets/Diamonds-Regular.woff2') format("woff2");
    }

    .minimal {
        position: relative;
        width: 100%;
        /*height: min(100vw, 100vh);*/
        height: 50vh;
    }

    #bg {
        z-index: 10;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        /*height: 100vh;*/
        height: 100%;
        /*height: 100vw;*/
    }

    #fg {
        z-index: 20;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        /*height: 100vh;*/
        height: 100%;
        /*height: 100vw;*/
    }

    .resizes {
        position: absolute;
        left: 100px;
        top: 10px;
        z-index: 30;
        color: white;

        line-height: 0;
    }

</style>
