<template>
    <div class="home">

<!--        <div v-if="false">-->
        <div>
            <Banner></Banner>
        </div>

<!--        <div class="minimal"></div>-->




        <div class="mission-section">
            <div class="card mission-card card-centre">
                <div class="heading">Our Mission</div>
                <div class="bullet trans from-right">Improve your business using web technologies</div>
                <br>
                <div class="bullet trans from-right">Bespoke web apps</div>
                <div class="bullet trans from-right">Work better online</div>
            </div>
        </div>


        <div class="section">

            <div class="card hover-zoom trans">
                <PaymentAnim class="animated-icons payment-icons"></PaymentAnim>

                <div class="heading">Online Payments</div>
                <div class="bullet trans from-right">Easy and secure</div>
            </div>

            <div class="blurb bullet">
                <p>Confidence through industry leading providers</p>

                <div class="payment-logos">
<!--                    <table border="0" cellpadding="10" cellspacing="0" align="center"><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/uk/webapps/mpp/paypal-popup" title="How PayPal Works" onclick="javascript:window.open('https://www.paypal.com/uk/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-150px.png" border="0" alt="PayPal Logo"></a></td></tr></table>-->
                    <img class="pay-logo" src="@/assets/third-party/pp-logo-150px.webp" alt="paypal logo" style="height: 2rem">
                    <img class="pay-logo" src="@/assets/third-party/google-pay-mark_800.svg" alt="google pay logo" style="height: 4rem">
                    <img class="pay-logo" src="@/assets/third-party/Apple_Pay_Mark_RGB_041619.svg" alt="apple pay logo" style="height: 3rem">
                    <img class="pay-logo" src="@/assets/third-party/Microsoft-Pay.jpg" alt="microsoft pay logo" style="height: 4rem">
                    <img class="pay-logo" src="@/assets/third-party/mc_vrt_pos.svg" alt="mastercard logo" style="height: 4rem">
                    <img class="pay-logo" src="@/assets/third-party/Amex_logo_color.svg" alt="amex logo" style="height: 4rem">
                    <img class="pay-logo" src="@/assets/third-party/VisaWhite-crop.svg" alt="visa logo" style="height: 4rem">
                </div>
                <p>Find out more about <router-link class="link" to="/payments">Online Payments</router-link></p>
            </div>

        </div>



        <div class="section reverse">

            <div class="card hover-zoom trans">
                <ClickAndCollectAnim class="animated-icons click-and-collect-icons"></ClickAndCollectAnim>

                <div class="heading">Click and Collect</div>
                <div class="bullet trans from-left">Browse and order online</div>
                <div class="bullet trans from-left">Payments in advance</div>
            </div>

            <div class="blurb bullet">
                <p>Avoid the telephone bottleneck and human error.</p>
                <p>Your customers can choose the best time for them and see busy periods.</p>
                <p>Find out more about <router-link class="link" to="/click-and-collect">Click and Collect</router-link></p>
            </div>

        </div>


        <div class="section">

            <div class="card hover-zoom trans">
                <SignUpAnim class="animated-icons auth-icons"></SignUpAnim>

                <div class="heading">Customer Sign-up</div>
                <div class="bullet trans from-left">Quick, easy and secure</div>
                <div class="bullet trans from-left">Industry-leading provider</div>
            </div>

            <div class="blurb bullet">
                <p>Send automated emails</p>
                <p>Discover frequent customers and more</p>
                <p>Find out more about <router-link class="link" to="/auth">Customer Sign-Up</router-link></p>
            </div>

        </div>



        <div class="websites-content">

            <div class="websites-banner">
                <div class="card card-centre websites-card">
                    <div class="heading">Our Websites</div>
                </div>
            </div>


            <div class="section">
                <a href="https://ditsyandglitzy.com" target="_blank">
                <div class="card hover-zoom trans">
                    <img class="site-image" src="../assets/sites/ditsy.jpg">

                    <div class="heading">Ditsy and Glitzy</div>
                    <div class="bullet trans from-left">Online Jewellery Shop</div>
                </div>
                </a>

                <div class="blurb smaller-text bullet">
                    <p>Bespoke e-commerce site built from scratch</p>
                    <p>Prerendered for ultra fast page loads, SEO compatibility, Facebook crawler, etc</p>
                    <p>Royal Mail Click and Drop integration</p>
                    <p>Automated Emails</p>
                    <p><img src="@/assets/third-party/logo-built_black.svg" alt="firebase logo" height="70px"></p>
                </div>
            </div>



            <div class="section reverse">
                <a href="https://ogwellpreschool.org" target="_blank">
                    <div class="card hover-zoom trans">
                        <img class="site-image" src="../assets/sites/ogwellpreschool.jpg">

                        <div class="heading">Ogwell Preschool</div>
<!--                        <div class="bullet trans from-right">Endearing, animated landing page</div>-->
<!--                        <div class="bullet trans from-right">Reassuring visual style</div>-->
                    </div>
                </a>

                <div class="blurb smaller-text bullet">
                    <p>Website for a charity preschool, replacing their previous WordPress content
                        which looked uninspiring and was cumbersome to maintain.</p>
                    <p>Following release of this site, the preschool saw
                        a significant increase in business, being fully booked for the foreseeable future
                        for the first time in at least a decade.</p>
                </div>
            </div>


            <div class="section">
                <a href="https://byronschipshop.web.app/#/" target="_blank">
                <div class="card hover-zoom trans">
                    <img class="site-image" src="../assets/sites/byronschipshop.jpg">

                    <div class="heading">Byron's Chip Shop</div>
                    <div class="bullet trans from-left">Click and collect demonstration</div>
<!--                    <div class="bullet trans from-left">Available for purchase</div>-->
                </div>
                </a>

                <div class="blurb smaller-text bullet">
                    <p>Click and collect</p>
                    <p>Online payments</p>
                    <p>Customer log-in</p>
                    <p>Automated emails</p>
                </div>
            </div>



            <div class="section reverse">
                <a href="https://bblay.com" target="_blank">
                <div class="card hover-zoom trans">
                    <img class="site-image" src="../assets/sites/bblay.png">

                    <div class="heading">Developer's Website</div>
                    <div class="bullet trans from-left">Personal page</div>
                    <div class="bullet trans from-left">Showcase interactive banner</div>
                </div>
                </a>

                <div class="blurb smaller-text bullet">
                    <p>Website for Red Kite's sole practitioner.</p>
                    <p>Aimed at coders and technophiles.</p>
                </div>
            </div>

            <br>
            <br>
            <br>

        </div>

        <NavBar></NavBar>


    </div>
</template>

<script>
    // import Websites from "../components/Websites";
    import Banner from "../components/Banner";
    import ClickAndCollectAnim from "../components/anims/ClickAndCollectAnim";
    import PaymentAnim from "../components/anims/PaymentAnim";
    import SignUpAnim from "../components/anims/SignUpAnim";
    import NavBar from "../components/NavBar";


    export default {
        name: 'Home',
        components: {
            NavBar,
            SignUpAnim,
            PaymentAnim,
            ClickAndCollectAnim,
            Banner,
        },

        // data() {
        //     return {
        //     };
        // },

        mounted() {
            this.onScroll();
            window.addEventListener("scroll", this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.onScroll)
        },
        methods: {

            onScroll(e) {  // eslint-disable-line no-unused-vars

                let topBorder = 0.05 * window.innerHeight;
                let botBorder = 0.05 * window.innerHeight;

                let movers = document.getElementsByClassName("trans");
                for (let mover of movers) {
                    let rect = mover.getBoundingClientRect();

                    let topDist = rect.bottom;
                    let botDist = window.innerHeight - rect.top;

                    if (botDist < botBorder) {
                        this.transition(mover, "below");
                    } else if (topDist < topBorder) {
                        this.transition(mover, "above");
                    } else {
                        this.transition(mover, "visible");
                    }
                }

            },

            // todo: different direction for top and bottom
            transition(el, state) {
                if (state === "visible") {
                    el.style.opacity = '100%';
                } else {
                    el.style.opacity = '0%';
                }

                if (el.className.includes('from-right')) {
                    if (state === "below") {
                        el.style.transform = 'translateX(10vw)';
                    } else if (state === "visible") {
                        el.style.transform = 'translateX(0)';
                    } else if (state === "above") {
                        el.style.transform = 'translateX(-10vw)';
                    } else {
                        console.error("unexpected transition state", state);
                    }
                }

                if (el.className.includes('from-left')) {
                    if (state === "below") {
                        el.style.transform = 'translateX(-10vw)';
                    } else if (state === "visible") {
                        el.style.transform = 'translateX(0)';
                    } else if (state === "above") {
                        el.style.transform = 'translateX(10vw)';
                    } else {
                        console.error("unexpected transition state", state);
                    }
                }

                // if (el.className.includes('from-small')) {
                //     if (state) {
                //         el.style.transform = 'scale(1.0)';
                //     } else {
                //         el.style.transform = 'scale(0.5)';
                //     }
                // }
            },

        }

    }
</script>

<style scoped lang="scss">

    @import "views-common";

    /*.link {*/
    /*    padding: 5px 10px 5px 10px;*/
    /*}*/

    .mission-section {
        /*position: relative;*/
        padding: 5px;
        background-image: linear-gradient(to bottom, #404040, #404040 38%, #ffffff 38.001%, #ffffff);
        /*background-image: linear-gradient(to bottom, #ffebe6, #ffffff);*/
    }






    .mission-card {
        border-radius: 0;
        /*margin-top: 10px;*/
        padding: 10px 30px 25px 30px;
        max-width: 75%;
    }


    .animated-icons {
        position: relative;

        margin: 10px;
        border-radius: 15px;
        /*width: 30vw;*/
        /*height: 30vh;*/
    }

    .click-and-collect-icons, .payment-icons, .auth-icons {
        width: 30vw;
        height: 18vw;
    }
    @media (max-width: 600px) {
        .click-and-collect-icons, .payment-icons, .auth-icons {
            width: 60vw;
            height: 36vw;
        }
    }


    .site-image {
        margin: 10px;
        border-radius: 15px;
        width: 40vw;
    }
    @media (max-width: 600px) {
        .site-image {
            width: 75vw;
        }
    }




        .trans {
        // todo: put this into code so it can be different each way
        transition: all 1s cubic-bezier(0.3, 0, 0.5, 1);
        opacity: 0;
    }

    .from-right {
        transform: translateX(10vw);
    }

    .from-left {
        transform: translateX(-10vw);
    }


    .websites-banner {
        background-image: linear-gradient(to bottom, #ffffff, #ffffff 38%, #c0c0c0 38.001%, #c0c0c0);
    }
    .websites-card {
        border-radius: 0;
        padding: 10px 40px 20px 40px;

    }

    .websites-content {
        background-color: silver;
    }



    .minimal {
        position: relative;
        width: 100%;
        /*height: 100vh;*/
        /*height: 100%;*/
        height: 100vw;
    }


    .payment-logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        //justify-content: flex-start;
        align-items: center;

        //display: grid;
        //grid-auto-columns: max-content;
        //grid-template-columns: repeat(auto-fit, min-content);
        //grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

        //justify-content: start;

        //width: 100%;

    }

    .pay-logo {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }

    a {
        text-decoration: none;
    }

    .smaller-text {
        font-size: 1.5rem;
    }

</style>
