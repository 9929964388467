<template>

    <div class="click-and-collect-icons">
        <div id="goods-outer" class="animated-icon goods-outer">
            <img id="goods" src="../../assets/goods.svg">
        </div>
        <img id="bag" class="animated-icon bag" src="../../assets/bag.svg">
        <img id="mouse" class="animated-icon mouse" src="../../assets/mouse.svg">
        <img id="mouse-click" class="animated-icon mouse-click" src="../../assets/mouse-click.svg">
    </div>

</template>

<script>

    import {getAnimElementById} from "./anim_common";

    let animInterval = null;

    let animData = {
        mouse: {
            lastX: 80,
            lastY: 70,
        },
        goods: {
            xRange: 20,
            startY: -30,
            endX: 35,
            endY: 65,
        },
    };

    export default {
        name: "ClickAndCollectAnim",

        mounted() {
            this.animateIcons();
            animInterval = setInterval(this.animateIcons, 4000);
        },

        beforeDestroy() {
            if (animInterval) {
                clearInterval(animInterval);
            }
        },

        methods: {

            animateIcons() {
                if (!document.hasFocus()) {
                    return;
                }

                this.animateMouse();
                this.animateGoods();
                this.animateBag();
            },

            animateMouse() {
                let mouse = getAnimElementById('mouse');
                let mouseClick = getAnimElementById('mouse-click');
                if (!mouse || !mouseClick) {
                    return
                }

                let newX = 75 + Math.round(Math.random() * 15);
                let newY = 65 + Math.round(Math.random() * 15);
                mouse.animate(
                    [
                        {left: `${animData.mouse.lastX}%`, easing: 'ease'},
                        {left: `${newX}%`},
                    ],
                    {duration: 1500, fill: "forwards"}
                );
                mouse.animate(
                    [
                        {top: `${animData.mouse.lastY}%`, easing: 'ease-in-out'},
                        {top: `${newY}%`},
                    ],
                    {duration: 1500, fill: "forwards"}
                );

                mouseClick.animate(
                    [
                        {opacity: 0, left: `${newX}%`, top: `${newY}%`},
                        {opacity: 0, offset: 0.8, easing: 'step-end'},
                        {opacity: 1, offset: 0.9, easing: 'step-end'},
                        {opacity: 0, left: `${newX}%`, top: `${newY}%`},
                    ],
                    {duration: 2000, fill: "forwards"}
                );

                animData.mouse.lastX = newX;
                animData.mouse.lastY = newY;
            },

            animateGoods() {
                let goods = getAnimElementById('goods');
                let goodsOuter = getAnimElementById('goods-outer');
                if (!goods || !goodsOuter) {
                    return;
                }

                let startX = animData.goods.endX - animData.goods.xRange / 2 + Math.random() * animData.goods.xRange;
                goodsOuter.animate(
                    [
                        {left: `${startX}%`},
                        {left: `${startX}%`, offset: 0.66, easing: 'ease-out'},
                        {left: `${animData.goods.endX}%`},
                    ],
                    {duration: 3000}
                );

                goodsOuter.animate(
                    [
                        {top: `${animData.goods.startY}%`},
                        {top: `${animData.goods.startY}%`, offset: 0.66, easing: 'ease-in'},
                        {top: `${animData.goods.endY}%`},
                    ],
                    {duration: 3000}
                );

                let rot = -90 + Math.random() * 180;
                goods.animate(
                    [
                        {opacity: 0, transform: 'rotate(0)'},
                        {opacity: 0, transform: 'rotate(0)', offset: 0.66},
                        {opacity: 1, offset: 0.70},
                        {opacity: 1, offset: 0.95},
                        {opacity: 0, transform: `rotate(${rot}deg)`},
                    ],
                    {duration: 3000}
                );

            },

            animateBag() {
                let bag = getAnimElementById('bag');
                if (!bag) {
                    return
                }

                let rot = -3 + Math.random() * 6;
                bag.animate(
                    [
                        {transform: `translate(-50%, -50%) scale(1.0)`},
                        {transform: `translate(-50%, -50%) scale(1.0) rotate(0)`, offset: 0.75},
                        {transform: `translate(-50%, -48%) scale(1.02) rotate(${rot}deg)`, offset: 0.77},
                        {transform: `translate(-50%, -50%) scale(1.0) rotate(0)`, offset: 0.83},
                        {transform: `translate(-50%, -50%) scale(1.0)`},
                    ],
                    {duration: 4000}
                );

            },
        }
    }

</script>

<style scoped lang="scss">

    @import "anim_common.css";

    .click-and-collect-icons {
        background-image: linear-gradient(to right bottom, #fdffca, #fcf5bb, #fdebad, #fee19f, #ffd693);
    }

    #bag {
        left: 35%;
        top: 50%;
        height: 16vw;
    }

    #goods-outer {
        left: 35%;
        top: 65%;
    }

    #goods {
        height: 8vw;
    }

    #mouse {
        left: 80%;
        top: 70%;
        height: 8vw;
    }
    #mouse-click {
        height: 8vw;
    }

    @media (max-width: 600px) {
        #bag {
            height: 32vw;
        }

        #goods {
            height: 16vw;
        }

        #mouse {
            height: 16vw;
        }
        #mouse-click {
            height: 16vw;
        }
    }



</style>