import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ClickAndCollect from "../views/ClickAndCollect";
import Payments from "../views/Payments";
import Auth from "../views/Auth";
import Tech from "../views/Tech";

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: Home},
  {path: '/click-and-collect', name: 'ClickAndCollect', component: ClickAndCollect},
  {path: '/payments', name: 'Payments', component: Payments},
  {path: '/auth', name: 'Auth', component: Auth},
  {path: '/tech', name: 'Tech', component: Tech},

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
