<template>
    <div>

        <NavBar></NavBar>


        <div class="card-centre">
            <div class="heading">Technology</div>

            <div class="bullet">
                <p>We use cutting edge technology and have the experience to be able to use it effectively.</p>
            </div>
        </div>


        <div class="section">

            <div class="blurb bullet">
                <p>We use Google's flagship <a class="link" href="https://firebase.google.com/" target="_blank">Firebase</a> platform for</p>
                <p><a class="link" href="https://firebase.google.com/products/hosting" target="_blank">website hosting</a></p>
                <p><a class="link" href="https://firebase.google.com/products/auth" target="_blank">user authentication</a></p>
                <p><a class="link" href="https://firebase.google.com/products/functions" target="_blank">serverless cloud computing</a></p>
                <p>This industry-leading platform provides a simple, secure, cutting-edge environment for our web apps.</p>
            </div>

            <img class="logo card-image" src="../assets/third-party/logo-built_black.svg">

        </div>


        <div class="section">

            <img class="logo card-image" src="../assets/third-party/Stripe wordmark - slate.svg">

            <div class="blurb bullet">
                <p>
                    We use <a class="link" href="https://firebase.google.com/" target="_blank">Stripe</a> for online payments.
                    <br>
                    This industry-leading service provides secure transactions and has a
                    <a class="link" href="https://stripe.com/docs/dashboard" target="_blank">feature-rich web site</a>.
                </p>
                <p>
                    Stripe allows a variety of <a class="link" href="https://stripe.com/en-gb/payments/features#payment-options" target="_blank">payment options</a>
                    including card, Google Pay and Apple Pay.
                </p>
                <p>Roadmap: PayPal</p>
                <p><router-link class="link" to="/payments">Find out more about our online payments</router-link></p>
            </div>

        </div>


        <div class="section">

            <div class="blurb bullet">
                <p>
                    Our web apps use the
                    <a class="link" href="https://vuejs.org/" target="_blank">Vue</a>
                    javascript framework.
                </p>
                <p>
                    We chose Vue for its performance and productivity.
                </p>

            </div>

            <img class="logo card-image" src="../assets/third-party/vue-badge-outlined.svg">

        </div>



    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    export default {
        name: "Tech",
        components: {NavBar}
    }
</script>

<style scoped lang="scss">
    @import "views-common";

    .logo {
        width: 30vw;
    }

</style>