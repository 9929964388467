<template>
    <div>

        <NavBar></NavBar>

        <div class="card-centre">
            <div class="heading">Click and Collect</div>

            <div class="bullet">
                <p>
                    Our click and collect system is built on robust
                    <router-link class="link" to="/tech">technology</router-link>
                    and principles.
                </p>
            </div>
        </div>



        <div class="section">

            <div class="blurb bullet">
                <p>In this screenshot from a <a class="link" href="https://byronschipshop.web.app/#/" target="_blank">working website</a>,
                    the customer can see how many customers will be visiting during each time slot.
                    They can avoid busy times if they like, and won't be able to book a collection
                    time which is completely full.
                </p>

                <p>When they pick a slot, they will be asked for
                    <router-link class="link" to="/payments">payment</router-link>
                    or, if they prefer to pay on collection, they will be asked to first
                    <router-link class="link" to="/auth">sign in</router-link>
                    so we know their email address.
                </p>

            </div>

            <div class="card">
                <img id="pick-a-slot-img" class="card-image" src="../assets/pick-a-slot.jpg">

                <div class="heading">Customer View</div>
                <div class="bullet">Shows busy times</div>
                <div class="bullet">Can't overbook</div>
            </div>

        </div>




        <div class="section">

            <div class="card">
                <img id="staff-view-img" class="card-image" src="../assets/staff-view.jpg">

                <div class="heading">Staff View</div>
                <div class="bullet">Shows upcoming orders</div>
            </div>

            <div class="blurb bullet">
                <p>
                    Here's the same data, viewed from the staff kitchen page.
                    Staff can see the customer's name and what they ordered.
                </p>
            </div>

        </div>



    </div>
</template>

<script>
    import NavBar from "../components/NavBar";

    export default {
        name: "ClickAndCollect",
        components: {NavBar}
    }
</script>

<style scoped lang="scss">
    @import "views-common";


    #pick-a-slot-img {
        width: 368px;
        height: 220px;
    }

    #staff-view-img {
        width: 45vw;
    }

</style>