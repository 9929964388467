<template>
    <div>
        <svg
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                width="1000"
                height="1000"
                viewBox="0 0 264.58333 264.58334"
                id="svg8">

            <defs id="defs2"
            />

            <metadata
                    id="metadata5">
                <rdf:RDF>
                    <cc:Work
                            rdf:about="">
                        <dc:format>image/svg+xml</dc:format>
                        <dc:type
                                rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                        <dc:title/>
                    </cc:Work>
                </rdf:RDF>
            </metadata>

            <g
                    id="layer1">

                <path
                        transform="scale(0.26458333)"
                        d="M 481.27734,96.974609 A 207.08127,209.10157 0 0 0 274.76172,306.07617 207.08127,209.10157 0 0 0 382.84766,489.73633 v 25.4414 h -1.01954 c -110.79998,0 -199.99999,89.19999 -200,200 v 72.74219 h 600.0293 v -72.74219 c 0,-110.80001 -89.19997,-200 -200,-200 h -1.01953 V 489.73828 A 207.08127,209.10157 0 0 0 688.92383,306.07617 207.08127,209.10157 0 0 0 481.8418,96.974609 a 207.08127,209.10157 0 0 0 -0.56446,0 z"
                        :style="style"
                        id="path841"/>

            </g>
        </svg>
    </div>

</template>

<script>

    export default {
        name: "Head",
        props: ["fill", "stroke", "fillOpacity", "strokeWidth"],
        data() {
            return {
                // style: `fill:${this.fill};fill-opacity:${this.fillOpacity};stroke:${this.stroke};stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`,
            };
        },
        computed: {
            style: function() {
                return `fill:${this.fill};fill-opacity:${this.fillOpacity};stroke:${this.stroke};stroke-width:${this.strokeWidth};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`;
            },
        },
    }

</script>

<style scoped>
    svg {
        /*width: 150px;*/
        width: 100%;
    }

</style>
