<template>
    <div>

        <NavBar></NavBar>


        <div class="card-centre">
            <div class="heading">Customer Sign-In</div>

            <div class="bullet">
                <p>
                    Also known as <i>Authentication</i>.
                </p>

                <p>
                    We use <a class="link" href="https://firebase.google.com/products/auth" target="_blank">Firebase Authentication</a>.
                    <br>
                    This industry-leading service provides simple, secure authentication.
                </p>

            </div>
        </div>


        <div class="section">

            <div class="blurb bullet">
                <p>
                    In this screenshot from a <a class="link" href="https://byronschipshop.web.app/#/" target="_blank">working website</a>,
                    we've integrated the Firebase Auth controls.
                </p>

                <p>
                    If we need a extra level of trust, we can require the user to verify their email address.
                </p>
            </div>

            <div class="card">
                <img id="pick-a-slot-img" class="card-image" src="../assets/login.jpg">

                <div class="heading">Firebase Auth</div>
                <div class="bullet">Simple and secure</div>
            </div>

        </div>


        <div class="section">

            <div class="card">
                <img class="card-image" src="../assets/login-first-time.jpg">

                <div class="heading">First Time Login</div>
<!--                <div class="bullet">Requests a name and password</div>-->
            </div>


            <div class="blurb bullet">
                <p>
                    When a user signs up they are asked for a few details.
                </p>

                <p>
                    Firebase ensures our website can't see their password.
                </p>
            </div>


        </div>









    </div>
</template>

<script>
    import NavBar from "../components/NavBar";

    export default {
        name: "Auth",
        components: {NavBar}
    }
</script>

<style scoped lang="scss">
    @import "views-common";

</style>