<template>
    <div class="nav-bar">
        <router-link class="link" to="/">Home</router-link>
        <router-link class="link" to="/click-and-collect">Click and Collect</router-link>
        <router-link class="link" to="/payments">Online Payments</router-link>
        <router-link class="link" to="/auth">Customer log-ins</router-link>
        <router-link class="link" to="/tech">Technology</router-link>
    </div>
</template>

<script>
    export default {
        name: "NavBar"
    }
</script>

<style scoped>

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
    @import "../views/views-common.scss";

    .nav-bar {
        /*width: 80%;*/
        /*margin: auto;*/
        padding: 20px 0 20px 0;

        font-size: 150%;
        font-family: 'Open Sans', sans-serif;

        /*overflow: hidden;*/

        border-style: solid none solid none;
        border-width: 1.5px;
        /*border-color: black;*/

        /*background-color: #c0c0c0;*/
        background-color: #f0f0f0;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }


    .link {
        margin: 5px;
    }

    /*.link {*/
    /*    margin: 5px;*/
    /*    text-decoration: none;*/

    /*    background-color: #909090;*/
    /*    border-radius: 20px;*/
    /*    padding: 5px 10px 5px 10px;*/
    /*    color: #f0f0f0;*/

    /*    transition: all 1s;*/
    /*}*/

    /*.link:hover {*/
    /*    background-color: #ff3300;*/
    /*    color: #ffffff;*/
    /*}*/

    /*.link:active {*/
    /*    background-color: #ff9900;*/
    /*    color: #ffffff;*/
    /*    transition: all 0s;*/
    /*}*/



</style>
